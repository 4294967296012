import { gql } from "@apollo/client";

const signUp = gql`
  mutation ($email: String!, $password: String!, $role: String!, $name: String!) {
    register(register: { email: $email, password: $password, role: $role, name: $name })
  }
`;

const signin = gql`
  mutation ($email: String!, $password: String!) {
    login(login: { email: $email, password: $password })
  }
`;

const loggedIn = gql`
  {
    loggedIn
  }
`;

const logout = gql`
  mutation {
    logout
  }
`;

const myRole = gql`
  {
    myRole
  }
`;

export { signin, signUp, loggedIn, logout, myRole };
