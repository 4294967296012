/* eslint-disable */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { GET_SONG } from "graphql/songs";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { Grid, Divider, Chip } from "@mui/material";
// eslint-disable-next-line
import { AccessTime, Description, MusicNote, Mood, Person, Public, QueueMusic, RecordVoiceOver, Star, Tune } from "@mui/icons-material";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import MDButton from "components/MDButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useState } from "react";
import PauseIcon from "@mui/icons-material/Pause";

const IconMap = {
  bpmFrom: <AccessTime />,
  bpmTo: <AccessTime />,
  description: <Description />,
  genre: <MusicNote />,
  keywords: <Star />,
  length: <AccessTime />,
  masterInfo: <RecordVoiceOver />,
  mood: <Mood />,
  privacyStatus: <Public />,
  producer: <Person />,
  publishingInfo: <Public />,
  recommendedFor: <QueueMusic />,
  songWriter: <Person />,
  theme: <Tune />,
  title: (
    <Typography variant="h5" component="h2" sx={{ mr: 1 }}>
      ♫
    </Typography>
  ),
  topLiner: <Person />,
};

function SongData() {
  const params = useParams();
  const [play, setPlay] = useState(false);
  const { loading, error, data } = useQuery(GET_SONG, { variables: { id: params.id } });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :</p>;

  // console.log(data.GetAudio);
  const url = `https://api.yourmusicvault.com/${data.GetAudio.audioFilePath}`;
  const filename = data.GetAudio.audioFilePath.split("/")[2];
  console.log(url);
  const audio = new Audio(url);
  const start = () => {
    if (!play) {
      audio.play();
      setPlay(true);
    } else {
      audio.pause();
      setPlay(false);
    }
  };

  const {
    bpm,
    description,
    genre,
    keywords,
    length,
    masterInfo,
    mood,
    privacyStatus,
    producer,
    publishingInfo,
    recommendedFor,
    songWriter,
    theme,
    title,
    topLiner,
  } = data.GetAudio;

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <Typography variant="h4">
          {title}
          {privacyStatus === "True" ? <LockIcon /> : <LockOpenIcon />}
        </Typography>
        <Grid container spacing={2} alignItems="center" mb={2} mt={2}>
          <Grid item xs={6}>
            <Grid container spacing={2} alignItems="center" mb={2}>
              <Grid item>
                <MusicNote />
              </Grid>
              <Grid item>
                <Typography variant="h5">Genre:</Typography>
                <Chip label={genre} />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" mb={2}>
              <Grid item>
                <Mood />
              </Grid>
              <Grid item>
                <Typography variant="h5">Mood:</Typography>
                <Chip label={mood} />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" mb={2}>
              <Grid item>
                <Description />
              </Grid>
              <Grid item>
                <Typography variant="h5">Description:</Typography>
                <Typography variant="h6">{description}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2} alignItems="center" mb={2}>
              <Grid item>{IconMap.length}</Grid>
              <Grid item>
                <Typography variant="h5">Length:</Typography>
                <Typography variant="h6">{length} seconds</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" mb={2}>
              <Grid item>
                <GraphicEqIcon />
              </Grid>
              <Grid item>
                <Typography variant="h5">BPM:</Typography>
                <Typography variant="h6">
                  {bpm}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" mb={2}>
              <Grid item>{IconMap.theme}</Grid>
              <Grid item>
                <Typography variant="h5">Theme:</Typography>
                <Typography variant="h6">{theme}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} alignItems="center" mb={3}>
          <Grid item xs={6}>
            <Typography variant="h5">Keywords:</Typography>
            <Typography variant="h6">{keywords}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5">Recommended for</Typography>
            <Typography variant="h6">{recommendedFor}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" mb={3}>
          <Grid item xs={6}>
            <Typography variant="h5">Master Info:</Typography>
            {masterInfo.map((item) => (
              <Typography variant="h6">{item.name}  &nbsp;  Master Points:{item.masterPoints} - {item.price} {item.currency}</Typography>
            )
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5">Publishing Info</Typography>
            {publishingInfo.map((item) => (
              <Typography variant="h6"> Producer: {item.publisherInfo} &nbsp; IPI: {item.ipi}  &nbsp;  Artist: {item.name} - {item.procent}%</Typography>
            )
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" mb={3}>
          <Grid item xs={6}>
            {/* eslint-disable-next-line */}
            <Typography variant="h5"> Producer </Typography>
            <Typography variant="h6">{producer}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5"> Songwriter </Typography>
            <Typography variant="h6">{songWriter}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" mb={3}>
          <Grid item xs={6}>
            {/* eslint-disable-next-line */}
            <Typography variant="h5"> Topliner </Typography>
            <Typography variant="h6">{topLiner}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5"> Private </Typography>
            <Typography variant="h6">{privacyStatus}</Typography>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5} p={2}>
        <Grid container spacing={2} alignItems="center" mb={3}>
          <Grid item xs={12}>
            <Card sx={{ display: "flex" }}>
              {/* eslint-disable-next-line */}
              <MDBox sx={{ display: "flex", flexDirection: "column" }} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography component="div" variant="h5">
                    {title}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" component="div">
                    {producer}
                  </Typography>
                </CardContent>
                <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
                  <audio src={url} controls controlsList="nodownload" style={{ width: "100%" }} />
                  {/* <IconButton aria-label="previous">
                    <SkipPreviousIcon />
                  </IconButton>
                  <IconButton onClick={start} aria-label="play/pause">
                    {play ? (
                      <PauseIcon sx={{ height: 38, width: 38 }} />
                    ) : (
                      <PlayArrowIcon sx={{ height: 38, width: 38 }} />
                    )}
                  </IconButton>
                  <IconButton aria-label="next">
                    <SkipNextIcon />
                  </IconButton>
                  */}
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default SongData;
