import { useLocation, Navigate } from "react-router-dom";
import useAuth from "hooks/useAuth";

function RequireAuth({ children }: { children: Element }) {
  const auth = useAuth();
  const location = useLocation();
  if (!auth.user) {
    return <Navigate to="/authentication/sign-in" state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth;
