import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Icon from "@mui/material/Icon";
import { useFormik, FormikProvider, FieldArray } from "formik";
// import { CREATE_NEW_AUDIO } from "graphql/songs";
// import { useMutation } from "@apollo/client";
import axios from "axios";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_NEW_AUDIO } from "graphql/songs";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

function AddSong() {
  const navigate = useNavigate();

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // eslint-disable-next-line
  const [addSong, { data, loading, error }] = useMutation(CREATE_NEW_AUDIO);

  // if (loading) return "Submitting...";
  // if (error) return `Submission error! ${error.message}`;
  const [message, setMessage] = useState("");
  const [duration, setDuration] = useState(0);
  const [bpm, setBpm] = useState(0);
  const [tags, setTags] = useState([]);
  const [audioFilePath, setAudioFilePath] = useState("");
  const [uploading, setUploading] = useState(false);

  async function upload(e) {
    e.preventDefault();
    // addSong({ variables: values });
    const formData = new FormData();
    formData.append("file", file);
    setUploading(true);
    const response = await axios.post("https://api.yourmusicvault.com/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    setUploading(false);
    setDuration(parseFloat(response.data.info.duration));
    setBpm(parseFloat(response.data.info.bpm));
    setTags(response.data.info.tags);
    setAudioFilePath(response.data.filename);
  }

  const currencies = [
    {
      value: "USD",
      label: "$",
    },
    {
      value: "EUR",
      label: "€",
    },
    {
      value: "BTC",
      label: "฿",
    },
    {
      value: "JPY",
      label: "¥",
    },
  ];

  const formik = useFormik({
    initialValues: {
      title: audioFilePath,
      description: "",
      keywords: "",
      genre: tags,
      // eslint-disable-next-line
      bpm: bpm,
      mood: "",
      theme: "",
      length: duration,
      producer: "",
      songWriter: "",
      topLiner: "",
      recommendedFor: "",
      publishingInfo: [
        {
          procent: 0,
          name: "",
          ipi: "",
          publisherInfo: "",
        },
      ],
      masterInfo: [
        {
          price: 0,
          currency: "USD",
          masterPoints: "",
          name: "",
        },
      ],
      privacyStatus: "",
      vocalist: "",
    },
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        // eslint-disable-next-line
        addSong({ variables: { ...values, audioFilePath: audioFilePath } }).then(
          // eslint-disable-next-line
          (dataS) => {
            navigate("/songs", { replace: true });
          },
          (errorS) => {
            let resMessage = "";
            resMessage =
              (errorS.response && errorS.response.data && errorS.response.data.message) ||
              errorS.message ||
              errorS.toString();
            setMessage(resMessage);
          }
        );
      } catch (erroSr) {
        setMessage(erroSr);
      }
      resetForm();
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mx={2} mt={2} mb={3} py={3} px={2} textAlign="center">
              <MDBox>
                <MDTypography variant="h3"> Add New Song </MDTypography>
              </MDBox>
              <MDTypography variant="h5" color="secondary" mt={2}>
                This information will describe more about the song
              </MDTypography>
            </MDBox>
            <Card display="flex">
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                textAlign="center"
              >
                <MDTypography variant="h6" color="white">
                  Song INFO
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                {message && (
                  <MDTypography component="h1" variant="h5">
                    {message}
                  </MDTypography>
                )}
                <MDBox>
                  <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                      <MDBox mb={2}>
                        <MDInput
                          id="file"
                          name="file"
                          type="file"
                          onChange={handleFileChange}
                          label="Song"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      {/* eslint-disable-next-line */}
                      <MDButton onClick={upload} variant="gradient" color="dark">
                        Upload
                      </MDButton>
                      {uploading ? <CircularProgress color="inherit" /> : null}
                      <MDBox mb={2}>
                        <MDInput
                          id="title"
                          name="title"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.title}
                          label="Title"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          id="description"
                          name="description"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.description}
                          label="Description"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          id="keywords"
                          name="keywords"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.keywords}
                          label="Keywords"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          id="genre"
                          name="genre"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.genre}
                          label="Genre"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          id="bpm"
                          name="bpm"
                          type="number"
                          onChange={formik.handleChange}
                          value={formik.values.bpm}
                          label="BPM"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          id="mood"
                          name="mood"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.mood}
                          label="Mood"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          id="theme"
                          name="theme"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.theme}
                          label="Theme"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          id="length"
                          name="length"
                          type="number"
                          onChange={formik.handleChange}
                          value={formik.values.length}
                          label="Length"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel htmlFor="vocalist">Vocalist</InputLabel>
                          <Select
                            id="vocalist"
                            name="vocalist"
                            onChange={formik.handleChange}
                            value={formik.values.vocalist}
                          >
                            <MenuItem value="Female">Female</MenuItem>
                            <MenuItem value="Male">Male</MenuItem>
                          </Select>
                        </FormControl>
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          id="producer"
                          name="producer"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.producer}
                          label="Producer"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          id="songWriter"
                          name="songWriter"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.songWriter}
                          label="Songwriter"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          id="topLiner"
                          name="topLiner"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.topLiner}
                          label="Topliner"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          id="recommendedFor"
                          name="recommendedFor"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.recommendedFor}
                          label="Recommended For"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2} mt={2} display="flex" justifyContent="space-between">
                        <FieldArray name="publishingInfo">
                          {({ remove, push }) => (
                            <div>
                              <MDTypography variant="h6"> Splits </MDTypography>
                              {formik.values.publishingInfo.length > 0 &&
                                formik.values.publishingInfo.map((publishingInfo, index) => (
                                  // eslint-disable-next-line
                                  <div className="row" key={index}>
                                    <MDInput
                                      // id="masterInfo"
                                      name={`publishingInfo.${index}.publisherInfo`}
                                      type="text"
                                      // value={formik.values.masterInfo[index].masterPoints}
                                      label="Publisher Name"
                                      variant="standard"
                                      onChange={formik.handleChange}
                                    />
                                    <MDInput
                                      // id="masterInfo"
                                      name={`publishingInfo.${index}.name`}
                                      type="text"
                                      // value={formik.values.masterInfo[index].masterPoints}
                                      label="Artist Name"
                                      variant="standard"
                                      onChange={formik.handleChange}
                                    />
                                    <MDInput
                                      // id="masterInfo"
                                      name={`publishingInfo.${index}.ipi`}
                                      type="text"
                                      // eslint-disable-next-line
                                      // value={console.log(formik.values.masterInfo) && formik.values.masterInfo[index].price}
                                      label="IPI"
                                      variant="standard"
                                      onChange={formik.handleChange}
                                    />
                                    <TextField
                                      name={`publishingInfo.${index}.procent`}
                                      variant="standard"
                                      label="Percent"
                                      id="standard-adornment-weight"
                                      type="number"
                                      InputProps={{
                                        endAdornment: <InputAdornment>%</InputAdornment>,
                                      }}
                                      aria-describedby="standard-weight-helper-text"
                                      onChange={formik.handleChange}
                                    />
                                    <MDButton
                                      variant="gradient"
                                      color="error"
                                      onClick={() => remove(index)}
                                      ml={2}
                                    >
                                      X
                                    </MDButton>
                                  </div>
                                ))}
                              <MDButton
                                variant="gradient"
                                color="warning"
                                onClick={() =>
                                  push({ publisherInfo: "", name: "", ipi: "", procent: 0 })
                                }
                              >
                                Add Publisher
                              </MDButton>
                            </div>
                          )}
                        </FieldArray>
                      </MDBox>
                      <MDBox mb={2} mt={2} display="flex" justifyContent="space-between">
                        <FieldArray name="masterInfo">
                          {({ remove, push }) => (
                            <div>
                              <MDTypography variant="h6"> Master Points </MDTypography>
                              {formik.values.masterInfo.length > 0 &&
                                formik.values.masterInfo.map((masterInfo, index) => (
                                  // eslint-disable-next-line
                                  <div className="row" key={index}>
                                    <MDInput
                                      // id="masterInfo"
                                      name={`masterInfo.${index}.name`}
                                      type="text"
                                      // value={formik.values.masterInfo[index].masterPoints}
                                      label="Artist Name"
                                      variant="standard"
                                      onChange={formik.handleChange}
                                    />
                                    <MDInput
                                      // id="masterInfo"
                                      name={`masterInfo.${index}.masterPoints`}
                                      type="text"
                                      // value={formik.values.masterInfo[index].masterPoints}
                                      label="Master Points"
                                      variant="standard"
                                      onChange={formik.handleChange}
                                    />
                                    <MDInput
                                      // id="masterInfo"
                                      name={`masterInfo.${index}.price`}
                                      type="number"
                                      // eslint-disable-next-line
                                      // value={console.log(formik.values.masterInfo) && formik.values.masterInfo[index].price}
                                      label="Master Price"
                                      variant="standard"
                                      onChange={formik.handleChange}
                                    />
                                    <TextField
                                      id="standard-select-currency"
                                      select
                                      label="Currency"
                                      defaultValue="USD"
                                      variant="standard"
                                      p={1}
                                      mr={2}
                                    >
                                      {currencies.map((option) => (
                                        <MenuItem
                                          name={`masterInfo.${index}.currency`}
                                          key={option.value}
                                          value={option.value}
                                          onChange={formik.handleChange}
                                        >
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                    <MDButton
                                      variant="gradient"
                                      color="error"
                                      onClick={() => remove(index)}
                                      ml={2}
                                    >
                                      X
                                    </MDButton>
                                  </div>
                                ))}
                              <MDButton
                                variant="gradient"
                                color="warning"
                                onClick={() =>
                                  push({ name: "", price: 0, masterPoints: "", currency: "USD" })
                                }
                              >
                                Add Master
                              </MDButton>
                            </div>
                          )}
                        </FieldArray>
                      </MDBox>
                      <MDBox mb={2}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel htmlFor="privacyStatus">Privacy Status</InputLabel>
                          <Select
                            id="privacyStatus"
                            name="privacyStatus"
                            onChange={formik.handleChange}
                            value={formik.values.privacyStatus}
                          >
                            <MenuItem value="False" selected>
                              Public
                            </MenuItem>
                            <MenuItem value="True">Private</MenuItem>
                          </Select>
                        </FormControl>
                      </MDBox>
                      <MDBox mt={2} display="flex" justifyContent="space-between">
                        <MDBox />
                        <MDButton type="submit" variant="gradient" color="info">
                          Submit
                        </MDButton>
                      </MDBox>
                    </form>
                  </FormikProvider>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddSong;
