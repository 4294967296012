/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Images
import echos from "assets/images/echos.png";
import horizons from "assets/images/horizons.png";
import pulse from "assets/images/pulse.png";
import solitude from "assets/images/solitude.png";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useState } from "react";
import SongData from "./components/SongData";

function Overview() {
  const [messagex, setMessage] = useState(0);
  const chooseMessage = (message) => {
    setMessage(message);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header chooseMessage={chooseMessage}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={2}>
            <Divider orientation="vertical" sx={{ mx: 0 }} />
            <Grid item xl={8}>
              <SongData />
            </Grid>
            <Grid item xs={12} md={6} xl={3} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              {messagex ? (
                <PlatformSettings />
              ) : (
                <ProfileInfoCard
                  title="profile information"
                  description="Hey there, I'm Richard Davis, a passionate music producer with a love for creating and producing music that moves people. With several years of experience in the music industry, I've developed a unique sound that blends different genres and styles, resulting in unforgettable melodies and compelling lyrics.
                  I take pride in my ability to craft music that resonates with listeners on a deep and emotional level. Whether it's a heartfelt ballad or an upbeat dance track, my goal is always to create music that inspires and uplifts."
                  info={{
                    fullName: "Richard Davis",
                    mobile: "(44) 123 1234 123",
                    email: "Richard Davis@mail.com",
                    location: "USA",
                  }}
                  social={[
                    {
                      link: "https://www.facebook.com/Richard Davis/",
                      icon: <FacebookIcon />,
                      color: "facebook",
                    },
                    {
                      link: "https://twitter.com/Richard Davis",
                      icon: <TwitterIcon />,
                      color: "twitter",
                    },
                    {
                      link: "https://www.instagram.com/Richard Davis/",
                      icon: <InstagramIcon />,
                      color: "instagram",
                    },
                  ]}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={false}
                />
              )}
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            {/* </Grid> */}
          </Grid>
        </MDBox>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Projects
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Top playlists
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={2}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={pulse}
                label="project #1"
                title="On Netflix"
                description="Check out epic track that landed on Netflix - it's a total game-changer and a wow-worthy project!"
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={echos}
                label="project #2"
                title="Billboard"
                description="Exciting news! Incredible track has reached the top of the Billboard Apple Music charts!"
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={solitude}
                label="project #3"
                title="Solitude"
                description="A minimalist and introspective piano album with sparse arrangements and gentle melodies that evoke a sense of calm and introspection"
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={horizons}
                label="project #4"
                title="Horizons"
                description="An uplifting and cinematic orchestral album with soaring melodies, lush strings, and epic percussion that inspire a sense of adventure and wonder."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
