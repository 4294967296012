/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, split, HttpLink } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider, AuthProvider } from "context";

const httpLink = new HttpLink({
  // uri: "http://localhost:4000/graphql",
  uri: "https://api.yourmusicvault.com/graphql",
  credentials: "include",
  mode: "cors",
});

const wsLink = new GraphQLWsLink(
  createClient({
    // url: "ws://localhost:4000/graphql",
    url: "wss://api.yourmusicvault.com/graphql",
    options: {
      reconnect: true,
    },
  })
);

/* eslint-disable no-eval */
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: splitLink,
  connectToDevTools: true,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);
